import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import { getMetadataValue } from "../../../utility/registration/RegFormUtility";

class TicketingCheckInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      emailCopied: false,
      numberOfGuests: 0,
      ticketTypes: props.ticketTypes
    }
    this.setEmailCopied = this.setEmailCopied.bind(this);
  }

  setEmailCopied(value) {
    navigator.clipboard.writeText(value);
    this.setState({ emailCopied: true });
  }

  submitParticipant(values) {
    const { savedFormCallback, loading, ticketTypes, participant } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var form_data = new FormData();

    values.user.checkinoptions = JSON.stringify(values.checkinoptions)
    values["participant"] = {"email": participant["email"]}
    values["metadata"] = values.user
    this.setState({
      loading: true,
    })
    if(loading) {
      return
    }
    fetch(`/admin/ticketing/update_user`, {
      method: `POST`,
      redirect: "manual",
      body: JSON.stringify(values),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    }).then(response => {
      try {
        return response.json();
      }
      catch(e) {
        this.setState({
          loading: false,
          checkError: true,
          error: "An error occurred. Please try again later."
        })
      }
    })
    .then(json => {
      if (json.error == null) {
        window.scrollTo(0,0);
        this.setState({
          loading: false,
          checkError: false
        })
        if(savedFormCallback) {
          savedFormCallback(json.participants)
        }
      } else {
        this.setState({
          checkError: true,
          error: "An error occurred. Please try again later.",
          loading: false,
        })
      }
    });
  }

  renderIndividualGuest(formikProps, ticketTypes, ticketsApplied, index) {
    const renderedHotelSuiteCheckin =  ticketTypes.map(ticket=> {
      let isEnabled = ticketsApplied[ticket.slug] || false;
      return (
      <div className="col-xs-12 text-center card sg-ticketing-dashboard-form-card">
        <div>
          <div>{this.renderCheckbox(`guest${index+1}hotelsuitecheckin${ticket.slug}`,formikProps, {disabled: !isEnabled})}</div>
          <div>{this.renderCheckbox(`guest${index+1}oncoursecheckin${ticket.slug}`,formikProps, {disabled: !isEnabled})}</div>
        </div>
      </div>
      );
    })

    const renderedTicketTitles = ticketTypes.map(ticket=> {
      let isEnabled = ticketsApplied[ticket.slug] || false;
      return (
        <div className={`col-xs-12 col-sm-3 text-center card sg-ticketing-dashboard-date-card ${isEnabled ? "" : "sg-ticketing-dashboard-date-card-disabled"}`}>
          <label style={{margin:0}}>{this.getTicketName(ticket.slug)}</label>
        </div>
      );
    })

    return (
      <div className="sg-admin-ticketing-main-attendance sg-admin-ticketing-guest-attendance">
        <div className="row">
          <div className="col-xs-12">
            {this.renderField(`guestname${index === 0 ? '' : index+1}`, formikProps, {class: "guestname-field", style: {color: '#0d274d'}})}
          </div>
          <div className="col-xs-12">
            <br/>
          </div>
        </div>
        <div className="">


        </div>
        <div className="row">
          <div className="col-xs-12">
            <div style={{display: "flex"}}>
              <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-start', fontWeight: '600'}}>Guest Attendee</div>
              <div className="row" style={{display: "flex", width: "100%"}}>
                {renderedTicketTitles}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div style={{display: "flex"}}>
              <div style={{minWidth: '40%', alignItems: 'flex-start', color: '#0D274D'}}>
                <div style={{lineHeight: "22px", marginBottom: "10px"}}>Hotel Suite Check In</div>
                <div style={{lineHeight: "22px", marginBottom: "10px"}}>On Course Suite Check In</div>
              </div>
              <div className="row" style={{display: "flex", width: "100%"}}>
                {renderedHotelSuiteCheckin}
              </div>
            </div>
            <div style={{display: "flex"}}>
              <div style={{minWidth: '40%', alignItems: 'flex-start', color: '#0D274D'}}>
                <div style={{lineHeight: "22px", marginBottom: "10px"}}>{`Guest #${index+1} Notes`}</div>
              </div>
              <div className="row" style={{display: "flex", width: "100%"}}>
                <div className="col-xs-12">
                  {this.renderField(`guestnotes${index === 0 ? '' : index+1}`, formikProps, {style: {width: "100%", backgroundColor: "#ffffff", border: "1px solid #0D274D"}})}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGuestAttendence(formikProps, ticketsApplied, maxTickets) {
    const { ticketTypes, participant } = this.props;
    if (maxTickets <= 1) {
      return (
        <div className="row">
          <div className="col-xs-12">
            No guest has been entered for this participant.
          </div>
        </div>
      )
    } 

    const renderedGuests = Array(maxTickets - 1).fill().map((x, index) =>
      this.renderIndividualGuest(formikProps, ticketTypes, ticketsApplied, index)
    )
    return renderedGuests;
  }

  getAttendeeColorClass(participant) {
    const type = getMetadataValue('attendeetype', participant["metadata"])
    const types = [
      {value: 'attendeecp', class: 'attendee-type-green'},
      {value: 'ciscoamhost', class: 'attendee-type-grey'},
      {value: 'ciscoexecutivehost', class: 'attendee-type-blue'},
      {value: 'execinvitenoncustomer', class: 'attendee-type-blue'},
      {value: 'ciscoemployeenonhost', class: 'attendee-type-grey'},
      {value: 'eventstaff', class: 'attendee-type-grey'},
      {value: 'charitableinvite', class: 'attendee-type-green'},
      {value: 'talent', class: 'attendee-type-blue'},
      {value: 'partnershipinvite', class: 'attendee-type-green'},
      {value: 'partnershipinvitecp', class: 'attendee-type-green'},
      {value: 'other', class: 'attendee-type-grey'}
    ];
    return types.find(x => x.value === type).class
  }

  renderMainAttendence(formikProps, ticketsApplied) {
    const { ticketTypes, participant } = this.props;
    const { emailCopied } = this.state;
    const renderedHotelSuiteCheckin = ticketTypes.map(ticket=> {
      let isEnabled = ticketsApplied[ticket.slug] || false;
      return (
        <div className="col-xs-12 text-center card sg-ticketing-dashboard-form-card">
          <div>
            <div>{this.renderCheckbox(`hotelsuitecheckin${ticket.slug}`,formikProps,{disabled: !isEnabled})}</div>
            <div>{this.renderCheckbox(`oncoursecheckin${ticket.slug}`,formikProps, {disabled: !isEnabled})}</div>
          </div>
        </div>
      );
    })

    const renderedTicketTitles = ticketTypes.map(ticket=> {
      let isEnabled = ticketsApplied[ticket.slug] || false;
      return (
        <div className={`col-xs-12 col-sm-3 text-center card sg-ticketing-dashboard-date-card ${isEnabled ? "" : "sg-ticketing-dashboard-date-card-disabled"}`}>
          <label style={{margin:0}}>{this.getTicketName(ticket.slug)}</label>
        </div>
      );
    })

    return (
      <div className="sg-admin-ticketing-main-attendance">
        <div className="row">
          <div className="col-xs-12" style={{display: "flex"}}>
            <div className={`attendee-type-circle ${this.getAttendeeColorClass(participant)}`} />
            <div>
              <div className="attendee-name">{participant.name_first} {participant.name_last}</div>
              <div className="attendee-details">
                {participant.job_title}, {participant.company}
                <br />
                <ReactTooltip id="my-tooltip">
                  <div style={{textAlign: "center"}}>
                    Click to copy
                    {
                      emailCopied ?
                      <><br />Copied!</>
                      :
                      <></>
                    }
                  </div>
                </ReactTooltip>
                <span onClick={() => {this.setEmailCopied(participant.email)}} data-tip data-for="my-tooltip">{participant.email}</span>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <br/>
          </div>
        </div>
        <div className="">


        </div>
        <div className="row">
          <div className="col-xs-12">
            <div style={{display: "flex"}}>
              <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-start', fontWeight: '600'}}>Main Attendee</div>
              <div className="row" style={{display: "flex", width: "100%"}}>
                {renderedTicketTitles}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div style={{display: "flex"}}>
              <div style={{minWidth: '40%', alignItems: 'flex-start', color: '#0D274D'}}>
                <div style={{lineHeight: "22px", marginBottom: "10px"}}>Hotel Suite Check In</div>
                <div style={{lineHeight: "22px", marginBottom: "10px"}}>On Course Suite Check In</div>
              </div>
              <div className="row" style={{display: "flex", width: "100%"}}>
                {renderedHotelSuiteCheckin}
              </div>
            </div>
            <div style={{display: "flex"}}>
              <div style={{minWidth: '40%', alignItems: 'flex-start', color: '#0D274D'}}>
                <div style={{lineHeight: "22px", marginBottom: "10px"}}>Title Type (Main Attendee)</div>
              </div>
              <div className="row" style={{display: "flex", width: "100%"}}>
                <div className="col-xs-12">
                  <div className="ticketing-select-wrapper">{this.renderSelectField(`confirmedtitletype`,formikProps, this.jobLevelOptions())}</div>
                </div>
              </div>
            </div>
            <div style={{display: "flex"}}>
              <div style={{minWidth: '40%', alignItems: 'flex-start', color: '#0D274D'}}>
                <div style={{lineHeight: "22px", marginBottom: "10px"}}>Alternate Email</div>
              </div>
              <div className="row" style={{display: "flex", width: "100%"}}>
                <div className="col-xs-12">
                  <div className="ticketing-select-wrapper">{this.renderField(`alternateemail`,formikProps, {not_required: true, style: {width: "100%", backgroundColor: "#ffffff", border: "1px solid #0D274D"}})}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }

  jobLevelOptions() {
    return [
      {value: "cxo", label: "CXO"},
      {value: "svp", label: "SVP"},
      {value: "vp", label: "VP"},
      {value: "director", label: "Director"},
      {value: "manager", label: "Manager"},
      {value: "other", label: "Other"}
    ]
  }

  renderSections(formikProps) {
    const { ticketTypes, participant } = this.props;
    const ticketsApplied = {}
    let maxTickets = 0
    ticketTypes.forEach(ticket => {
      if (getMetadataValue(ticket.slug, participant["metadata"]) !== '0' && getMetadataValue(ticket.slug, participant["metadata"]) !== '') {
        ticketsApplied[ticket.slug] = true;
        try {
          let count = parseInt(formikProps.values["user"][ticket.slug]);
          if (count > maxTickets) maxTickets = count;
        } catch(e) {
          console.log(e);
        }
      }
    });
    if (maxTickets > 2) maxTickets = 2;

    const sections = [
      {
        render: this.renderTicketsAllocated(formikProps, ticketsApplied)
      },

      {
        render: this.renderMainAttendence(formikProps, ticketsApplied)
      },

      {
        render: this.renderGuestAttendence(formikProps, ticketsApplied, maxTickets),
        noBorder: true
      },

      {
        render: this.renderNotesSection(formikProps)
      }
    ]

    return sections.map(section=> (
      <div className="sg-admin-ticketing-form-section" style={section.noBorder ? {border: "none"} : {}}>
        <div className="sg-admin-ticketing-form-section-header sg-registration-h3-copy ">
          {section.title}
        </div>
        <div className="sg-admin-ticketing-form-section-header sg-registration-large-copy">
          {section.subtitle}
        </div>
        <div className="sg-admin-ticketing-form-section-body">
          {section.render}
        </div>
      </div>
    ))
  }

  getTicketName(ticket_slug) {
    const names = [
      {value: 'day1', name: 'Friday'},
      {value: 'day2', name: 'Saturday'},
      {value: 'day3', name: 'Sunday'},
      {value: 'overnight1', name: 'Overnight'},
      {value: 'wimbledon1', name: 'Wimbledon'}
    ]
    return names.find(x => x.value === ticket_slug).name;
  }

  renderTicketsAllocated(formikProps, ticketsApplied) {
    const { participant } = this.props;
    const { ticketTypes } = this.state;
    const renderedTicketTypes =  ticketTypes.map(ticket=> {
      let isEnabled = ticketsApplied[ticket.slug] || false;
      return (
        <div className={`col-xs-12 col-sm-3 text-center card sg-ticketing-dashboard-count-card ${isEnabled ? "" : "sg-ticketing-dashboard-count-card-disabled"}`}>
          <label style={{margin:0}}>{this.getTicketName(ticket.slug)}</label>
          <div style={{fontSize: "14px"}}>
            <br/>
            {
              isEnabled ?
              <>{this.renderField(ticket.slug, formikProps, {not_required: true, style: {textAlign: "center",backgroundColor: "white",border:"1px solid #0D274D"}})}</>
              :
              <div style={{paddingTop: "4px"}}>{
                participant['metadata'].find(meta => meta.field_slug === ticket.slug) !== undefined ?
                participant['metadata'].find(meta => meta.field_slug === ticket.slug).value ||"0"
                :
                "0"
              }</div>
            }
          </div>
        </div>
      );
    })

    const renderedTicketTransfers =  ticketTypes.map(ticket=> {
      let isEnabled = ticketsApplied[ticket.slug] || false;
      return (
        <div className={`col-xs-12 col-sm-3 text-center card sg-ticketing-dashboard-count-card ${isEnabled ? "" : "sg-ticketing-dashboard-count-card-disabled"}`}>
          <label style={{margin:0,height:"0px",overflow:"hidden"}}>{this.getTicketName(ticket.slug)}</label>
          <div style={{fontSize: "14px"}}>
            {
              isEnabled ?
              <>{this.renderField(`${ticket.slug}transferred`, formikProps, {not_required: true, style: {textAlign: "center",backgroundColor: "white",border:"1px solid #0D274D"}})}</>
              :
              <div style={{paddingTop: "4px"}}>{participant.registration_answers[ticket.slug]||"0"}</div>
            }
          </div>
        </div>
      );
    })

    // const renderedClubTicketsAllocated = [
    //   {slug: 'day1', core_slug: 'club_day_1_ticket_transferred', label: 'club day1 transferred'},
    //   {slug: 'day2', core_slug: 'club_day_2_ticket_transferred', label: 'club day2 transferred'},
    //   {slug: 'day3', core_slug: 'club_day_3_ticket_transferred', label: 'club day3 transferred'},
    //   {slug: 'overnight1', core_slug: 'club_o_1_ticket_transferred', label: 'club overnight1 transferred'},
    //   {slug: 'wimbledon1', core_slug: 'club_w_1_ticket_transferred', label: 'club wimbledon1 transferred'}
    // ].map(club_ticket => {
    //   let isEnabled = ticketsApplied[club_ticket.slug] || false;
    //   return (
    //     <div className={`col-xs-12 col-sm-3 text-center card sg-ticketing-dashboard-count-card ${isEnabled ? "" : "sg-ticketing-dashboard-count-card-disabled"}`}>
    //       <label style={{margin:0,height:"0px",overflow:"hidden"}}>{club_ticket.label}</label>
    //       <div style={{fontSize: "14px"}}>
    //         {
    //           isEnabled ?
    //           <>{this.renderField(`${club_ticket.core_slug}`, formikProps, {not_required: true, style: {textAlign: "center",backgroundColor: "white",border:"1px solid #0D274D"}})}</>
    //           :
    //           <div style={{paddingTop: "4px"}}>{getMetadataValue(club_ticket.core_slug, participant["metadata"])||"0"}</div>
    //         }
    //       </div>
    //     </div>
    //   );
    // })

    // const renderedClubTicketsTransferred = [
    //   {slug: 'day1', core_slug: 'club_day_1_ticket_transferred', label: 'club day1 transferred'},
    //   {slug: 'day2', core_slug: 'club_day_2_ticket_transferred', label: 'club day2 transferred'},
    //   {slug: 'day3', core_slug: 'club_day_3_ticket_transferred', label: 'club day3 transferred'},
    //   {slug: 'overnight1', core_slug: 'club_o_1_ticket_transferred', label: 'club overnight1 transferred'},
    //   {slug: 'wimbledon1', core_slug: 'club_w_1_ticket_transferred', label: 'club wimbledon1 transferred'}
    // ].map(club_ticket => {
    //   let isEnabled = ticketsApplied[club_ticket.slug] || false;
    //   return (
    //     <div className={`col-xs-12 col-sm-3 text-center card sg-ticketing-dashboard-count-card ${isEnabled ? "" : "sg-ticketing-dashboard-count-card-disabled"}`}>
    //       <label style={{margin:0,height:"0px",overflow:"hidden"}}>{club_ticket.label}</label>
    //       <div style={{fontSize: "14px"}}>
    //         {
    //           isEnabled ?
    //           <>{this.renderField(`${club_ticket.core_slug}`, formikProps, {not_required: true, style: {textAlign: "center",backgroundColor: "white",border:"1px solid #0D274D"}})}</>
    //           :
    //           <div style={{paddingTop: "4px"}}>{getMetadataValue(club_ticket.core_slug, participant["metadata"])||"0"}</div>
    //         }
    //       </div>
    //     </div>
    //   );
    // })

    return (
      <div className="row">
        <div className="col-xs-12">
          <div style={{display: "flex"}}>
            <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-end'}}>Tickets allocated</div>
            <div className="row" style={{display: "flex"}}>
              <br/>
              <br/>
              {renderedTicketTypes}
              <br/>
              <br/>
            </div>
          </div>
          <div style={{display: "flex"}}>
            <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-end'}}>Tickets transferred</div>
            <div className="row" style={{display: "flex"}}>
              <br/>
              <br/>
              {renderedTicketTransfers}
              <br/>
              <br/>
            </div>
          </div>
          {/* <div style={{display: "flex"}}>
            <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-end'}}>Club Tickets allocated</div>
            <div className="row" style={{display: "flex"}}>
              <br/>
              <br/>
              {renderedClubTicketsAllocated}
              <br/>
              <br/>
            </div>
          </div>
          <div style={{display: "flex"}}>
            <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-end'}}>Club Tickets transferred</div>
            <div className="row" style={{display: "flex"}}>
              <br/>
              <br/>
              {renderedClubTicketsTransferred}
              <br/>
              <br/>
            </div>
          </div> */}
        </div>
      </div>
    )

  }

  renderNotesSection(formikProps) {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div style={{display: "flex"}}>
            <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-start'}}>Notes</div>
            <div className="row" style={{display: "flex", width: "100%"}}>
              <div className="col-xs-12">
                {this.renderField('checkinnotes',formikProps, {component: 'textarea', not_required: true, style: {width: "100%", backgroundColor: "#ffffff", border: "1px solid #0D274D"}})}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12"><br /></div>
        <div className="col-xs-12">
          <div style={{display: "flex"}}>
            <div style={{minWidth: '40%', display: 'flex', alignItems: 'flex-start'}}>Reg Notes</div>
            <div className="row" style={{display: "flex", width: "100%"}}>
              <div className="col-xs-12">
                {this.renderField('notes',formikProps, {component: 'textarea', not_required: true, style: {width: "100%", backgroundColor: "#ffffff", border: "1px solid #0D274D"}})}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCheckbox(name, formikProps, renderOptions={}) {
    let value = false;
    let customValidation = renderOptions.customValidation||false
    try {
      value = formikProps.values['checkinoptions'][name] == "True";
    }catch(e){

    }
    if(renderOptions['forceValue'] != undefined ) {
      value = formikProps.values['checkinoptions'][name] == renderOptions['forceValue']
    }
    if (renderOptions['disabled']) {
      return (
        <img
          src={"/images/admin/ticketing/checkbox-disabled.svg"}
          style={{ marginBottom: "12px"}}
        />
      );
    }
    return(
      <img
        src={ value  ? "/images/admin/ticketing/checkbox-checked-enabled.svg" : "/images/admin/ticketing/checkbox-unchecked-enabled.svg" }
        style={{cursor:"pointer", marginBottom: "12px"}}
        onClick={(e) => {
          const { checkinoptions } = formikProps.values;
          if(renderOptions['forceValue'] != undefined) {
            checkinoptions[name] = renderOptions['forceValue']
          }else {
            checkinoptions[name] = value ? "False" : "True";
            const now = Date.now();
            const dt = new Date(now);
            checkinoptions[`${name}timestamp`] = dt.toISOString();
          }

          formikProps.setFieldValue('checkinoptions', checkinoptions)
        }}
      />
    )
  }

  renderSelectField(name, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const customValidation = renderOptions['customValidation']||false;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }
    const customStyles = {
      control: base => ({
        ...base,
        height: 24,
        minHeight: 24,
        background: "#ffffff"
      }),
      valueContainer: base => ({
        ...base,
        height: 24,
        minHeight: 24
      }),
      indicators: base => ({
        ...base,
        height: 24,
        minHeight: 24
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 0
      }),
      input: base => ({
        ...base,
        padding: 0,
        margin: 0
      }),
    };
    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
        }}
        filterOption={this.customFilter}
        styles={customStyles}
      />
    )
  }

  isFormValid(formikProps) {
    return true;
  }

  attemptSubmit(formikProps = {}){
    if(!this.isFormValid(formikProps)){
      this.setState({
        error: "Please make sure all required fields have been properly filled in",
        checkError: true
      })
      return;
    }else {
      this.setState({
        checkError: false,
      })
      formikProps.submitForm();
      return;
    }
  }

  getInitialValues() {
    const { participant, ticketTypes } = this.props;
    let values = {
      checkinnotes: getMetadataValue('checkinnotes', participant["metadata"]),
      notes: getMetadataValue('notes', participant["metadata"]),
      confirmedtitletype: getMetadataValue('confirmedtitletype', participant["metadata"]),
      guestname: getMetadataValue('guestname', participant["metadata"]),
      // guestname2: getMetadataValue('guestname2', participant["metadata"]),
      // guestname3: getMetadataValue('guestname3', participant["metadata"]),
      alternateemail: getMetadataValue('alternateemail', participant["metadata"]),
      guestnotes: getMetadataValue('guestnotes', participant["metadata"]),
      // guestnotes2: getMetadataValue('guestnotes2', participant["metadata"]),
      // guestnotes3: getMetadataValue('guestnotes3', participant["metadata"]),
      // club_day_1_ticket_count: getMetadataValue('club_day_1_ticket_count', participant["metadata"]),
      // club_day_2_ticket_count: getMetadataValue('club_day_2_ticket_count', participant["metadata"]),
      // club_day_3_ticket_count: getMetadataValue('club_day_3_ticket_count', participant["metadata"]),
      // club_day_4_ticket_count: getMetadataValue('club_day_4_ticket_count', participant["metadata"]),
      // club_o_1_ticket_count: getMetadataValue('club_o_1_ticket_count', participant["metadata"]),
      // club_day_1_ticket_transferred: getMetadataValue('club_day_1_ticket_transferred', participant["metadata"]),
      // club_day_2_ticket_transferred: getMetadataValue('club_day_2_ticket_transferred', participant["metadata"]),
      // club_day_3_ticket_transferred: getMetadataValue('club_day_3_ticket_transferred', participant["metadata"]),
      // club_day_4_ticket_transferred: getMetadataValue('club_day_4_ticket_transferred', participant["metadata"]),
      // club_o_1_ticket_transferred: getMetadataValue('club_o_1_ticket_transferred', participant["metadata"]),
    }
    ticketTypes.map(ticket=>{
      values[ticket.slug] = getMetadataValue(ticket.slug, participant["metadata"]) === "" ? "0" : getMetadataValue(ticket.slug, participant["metadata"]),
      values[ticket.slug+"transferred"] = getMetadataValue(`${ticket.slug}transferred`, participant["metadata"]) === "" ? "0" : getMetadataValue(`${ticket.slug}transferred`, participant["metadata"])
    })
    return values;
  }

  getInitialCheckInOptions() {
    const { participant } = this.props;
    let checkInOptions = {}
    try{
      checkInOptions = JSON.parse(getMetadataValue('checkinoptions', participant["metadata"])||"{}")||{};
    }catch(e) {
      console.log(e);
      checkInOptions = {}
    }
    return checkInOptions
  }

  renderField(name,formikProps, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && ((formikProps.values.user[name]||"").length <= 0 || properties['customValidation']);
    if(properties['not_required']) {
      erroring = false || properties['customValidation'];
    }
    return (
      <Field
        {...(properties['inputProps']||{})}
        style={properties['style']}
        disabled={properties['disabled']} onKeyUp={() => properties['onKeyUp'](formikProps)}
        component={properties['component']}
        className={`form-control ${properties['disabled'] ? 'disabled': ""} ${erroring ? 'field-erroring': "" } ${properties['class'] ? properties['class'] : ''}`}
        type={properties['type']}
        name={`user[${name}]`}
        size={properties['size'] ? formikProps.values.user[name].length : 'default'}
      />
    )
  }

  renderCheckInField(name,formikProps, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && ((formikProps.values.checkinoptions[name]||"").length <= 0 || properties['customValidation']);
    if(properties['not_required']) {
      erroring = false || properties['customValidation'];
    }
    return (
      <Field {...(properties['inputProps']||{})} disabled={properties['disabled']} onKeyUp={() => properties['onKeyUp'](formikProps)} component={properties['component']} className={`form-control ${properties['disabled'] ? 'disabled': ""} ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`checkinoptions[${name}]`}/>
    )
  }


  isEmailTaken(formikProps) {
    const { participants, participant } = this.props;
    const { values } = formikProps;
    const { user } = values;
    const { email } = user
    return participants.filter(x=> x.gid != (participant||{}).gid).filter(x=>x.email == email).length > 0
  }

  render() {
    const { user, participant } = this.props;
    const { checkError, submitError, error, loading } = this.state;
    return (
      <div className="registration-form" onClick={(e)=>{e.stopPropagation()}}>
        <Formik
          initialValues={{
            gid: participant ? participant.gid : "",
            user: this.getInitialValues(),
            checkinoptions: this.getInitialCheckInOptions()
          }}
          onSubmit={(values, actions) => {
            this.submitParticipant(values)
          }}
          render={(formikProps) => (
            <Form
              onSubmit={e => { e.preventDefault(); e.stopPropagation(); }}
              id="sg-admin-participant-form sg-registration-copy"
              style={{border: "0", textAlign: 'left'}}
            >
              {this.renderSections(formikProps)}
              <div className="col-xs-12 text-center">
                <div className="registration-field-error text-center">
                  {checkError && !this.isFormValid(formikProps) ? error : ""}
                  {submitError}
                </div>
              </div>
              <div  className="col-xs-12 text-right" style={{paddingRight: "0px"}}>
                {loading ? (
                  <button disabled={true} className="sg-button sg-submit-button" style={{width: "auto"}}>
                     Saving...
                  </button>
                ):(
                  <button onClick={()=>this.attemptSubmit(formikProps)} className="sg-button sg-submit-button" style={{width: "auto"}}>
                     Submit
                  </button>
                )}
              </div>
            </Form>
        )}/>
      </div>
    );
  }
}

export default TicketingCheckInForm;
