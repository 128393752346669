import React from "react";

class SurveyButtonCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderContactUsButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`}>
        <a style={{textDecoration: "none"}} href="mailto:executiverelations@cisco.com?subject=Event%20Feedback%20%7C%20Cisco%20x%20McLaren%20Formula%201%20Team%20Experience%20%7C%20British%20Grand%20Prix%202024" target="_blank">
          <div className="sg-button sg-submit-button reg-button" style={{textAlign: "center"}}>
            {question.label}
          </div>
        </a>
      </div>
    );
  }

  render() {
    const { question, formikProps } = this.props;
    let renderedCustomButton = <></>;
    switch(question.properties.custom_function_id) {
      case 'contact-us':
        renderedCustomButton = this.renderContactUsButton(question, formikProps);
        break;
      default:
        return <></>;
    }
    return renderedCustomButton;
  }
}

export default SurveyButtonCustom;
